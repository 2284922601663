import { Icon } from '@iconify/react/dist/iconify'
import React from 'react'

const HeroSection = () => {
    return (
        <div className='hero-bg'>
            <div className='bg-overlay'></div>
            <div className='hero-contain d-flex flex-column justify-content-center align-items-center h-100 w-100'>
                <div className='col-xl-6 col-md-8 col-10 d-flex flex-column justify-content-center align-items-center'>
                    <h6 className='hero-sub-title text-center' data-aos="fade-down"  data-aos-duration="1000">Innovative Engineering Solutions for Efficiency</h6>
                    <h4 className='hero-title'  data-aos="fade-down"  data-aos-duration="1000"> Empowering Construction Excellence</h4>
                    <p className='hero-pargraph text-center'  data-aos="fade-down"  data-aos-duration="1000">
                    We specialize in providing innovative solutions for rebar and construction machinery. From precision-cutting to bending and shaping, our equipment is engineered for efficiency and durability. Join us in building a future of strength and reliability in construction projects worldwide</p>
                    {/* <button className='btn-count mt-2'  data-aos="fade-down"  data-aos-duration="1000">
                        Get started
                        <span className="line-1"></span>
                        <span className="line-2"></span>
                        <span className="line-3"></span>
                        <span className="line-4"></span>
                    </button> */}
                    <div class="arrow-container animated fadeInDown">
  <div class="arrow-2">
  <Icon icon="mingcute:down-line" />
  </div>
  <div class="arrow-1 animated hinge infinite zoomIn"></div>
</div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection
