import { Icon } from '@iconify/react'
import React from 'react'

const Service = () => {
    return (
        <section className='section-padding section-bottom'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-4' data-aos="zoom-in-up"  data-aos-duration="1000">
                        <div>
                            <div className='service-icon mx-auto'>
                                <Icon icon="tabler:clock" className='icon' />
                            </div>
                            <div className='text-center mt-4 service-content'>
                                <h4>round the clock</h4>
                                <p>Seamless support anytime every time</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-4' data-aos="zoom-in-up"  data-aos-duration="1000">
                        <div>
                            <div className='service-icon mx-auto'>
                                <Icon icon="mdi:location" className='icon' />
                            </div>
                            <div className='text-center mt-4 service-content'>
                                <h4>location</h4>
                                <p>Ahmedabad, Gujarat</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-4' data-aos="zoom-in-up"  data-aos-duration="1000">
                        <div>
                            <div className='service-icon mx-auto'>
                            <Icon icon="streamline:customer-support-1-solid" className='icon'/>
                            </div>
                            <div className='text-center mt-4 service-content'>
                                <h4>customer support</h4>
                                <p>+91 96876 09095</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Service
