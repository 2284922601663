import React from "react";
import PageHeader from "./components/common/pageHeader";
import { Icon } from "@iconify/react";

const ContactUs = () => {
  return (
    <>
      <PageHeader url="Contact us" />
      <section className="section-padding section-bottom">
        <div className="container">
          <h1
            className="page-title"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            Contact Us
          </h1>
          <div className="row mt-3">
            <div
              className="col-xl-4 col-md-6 col-12"
              data-aos="zoom-in-up"
              data-aos-duration="2000"
            >
              <div className="contact-us-card">
                <div className="contact-us-icon1">
                  <Icon icon="fa6-solid:house" />
                </div>
                <h6>Head Office Address</h6>
                <p className="mb-1">
                  120, Shyam Industrial Park, Kujad-Bhavda Road,
                  Bakrol(Bujrang), 382430, Ahmedabad, Gujarat 382445
                </p>
              </div>
            </div>
            <div
              className="col-xl-4 col-md-6 col-12"
              data-aos="zoom-in-up"
              data-aos-duration="2000"
            >
              <div className="contact-us-card">
                <div className="contact-us-icon2">
                  <Icon icon="solar:phone-bold" />
                </div>
                <h6>Mobile</h6>
                <p className="mb-1 cursor-pointer">
                  <a
                    href="tel:7990377232"
                    className="text-decoration-none  text-inherit"
                  >
                    +91 7990377232
                  </a>
                </p>
              </div>
            </div>
            <div
              className="col-xl-4 col-md-6 col-12"
              data-aos="zoom-in-up"
              data-aos-duration="2000"
            >
              <div className="contact-us-card">
                <div className="contact-us-icon3">
                  <Icon icon="ic:baseline-email" />
                </div>
                <h6>Email</h6>
                <p className="mb-1">
                  <a
                    href="https://morexengineering.com"
                    className="text-decoration-none  text-inherit"
                  >
                    pramukhequipments99@gmail.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
