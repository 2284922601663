import './App.css';
import Home from './Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AboutUs from './AboutUs';
import Gallery from './Gallery';
import ContactUs from './ContactUs';
import Product from './Product';
import Layout from './components/layout';

function App() {
  return (
    <div className="main">
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/about-us" element={<AboutUs />}></Route>
            <Route path="/gallery" element={<Gallery />}></Route>
            <Route path="/product" element={<Product />}></Route>
            <Route path="/contact-us" element={<ContactUs />}></Route>
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;