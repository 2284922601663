import React from 'react'
import PageHeader from './components/common/pageHeader'
import product1 from '../src/assets/images/product1.jpeg'
import product2 from '../src/assets/images/product2.jpeg'
import product3 from '../src/assets/images/product3.jpeg'
import product4 from '../src/assets/images/product4.jpeg'
import product5 from '../src/assets/images/product5.jpeg'
import product6 from '../src/assets/images/product6.jpeg'
import product8 from '../src/assets/images/product8.jpeg'
import product9 from '../src/assets/images/product12.jpeg'

const Gallery = () => {
    return (
        <>
            <PageHeader url="Gallery" />
            <section className='section-padding section-bottom'>
                <div className='container'>
                    <h1 className='page-title' data-aos="fade-up" data-aos-duration="1000">gallery</h1>
                    <div className='row mt-2 mt-md-5'>
                        <div className='col-xl-3 col-md-4 col-6 my-3' data-aos="zoom-in-right" data-aos-duration="1000">
                            <div className='overflow-hidden position-relative product-gallery-main'>
                                <img src={product1} className='w-100 object-fit-contain' alt="gallery image 1" />
                                <div className='product-overlay'></div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-md-4 col-6 my-3' data-aos="zoom-in-right" data-aos-duration="1000">
                            <div className='overflow-hidden position-relative product-gallery-main'>
                                <img src={product2} className='w-100 object-fit-contain' alt="gallery image 2" />
                                <div className='product-overlay'></div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-md-4 col-6 my-3' data-aos="zoom-in-right" data-aos-duration="1000">
                            <div className='overflow-hidden position-relative product-gallery-main'>
                                <img src={product3} className='w-100 object-fit-contain' alt="gallery image 3"  />
                                <div className='product-overlay'></div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-md-4 col-6 my-3' data-aos="zoom-in-right" data-aos-duration="1000">
                            <div className='overflow-hidden position-relative product-gallery-main'>
                                <img src={product4} className='w-100 object-fit-contain' alt="gallery image 4"  />
                                <div className='product-overlay'></div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-md-4 col-6 my-3' data-aos="zoom-in-right" data-aos-duration="1000">
                            <div className='overflow-hidden position-relative product-gallery-main'>
                                <img src={product5} className='w-100 object-fit-contain' alt="gallery image 5" />
                                <div className='product-overlay'></div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-md-4 col-6 my-3' data-aos="zoom-in-right" data-aos-duration="1000">
                            <div className='overflow-hidden position-relative product-gallery-main'>
                                <img src={product6} className='w-100 object-fit-contain' alt="gallery image 6" />
                                <div className='product-overlay'></div>
                            </div>
                        </div>

                        <div className='col-xl-3 col-md-4 col-6 my-3' data-aos="zoom-in-right" data-aos-duration="1000">
                            <div className='overflow-hidden position-relative product-gallery-main'>
                                <img src={product8} className='w-100 object-fit-contain' alt="gallery image 7" />
                                <div className='product-overlay'></div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-md-4 col-6 my-3' data-aos="zoom-in-right" data-aos-duration="1000">
                            <div className='overflow-hidden position-relative product-gallery-main'>
                                <img src={product9} className='w-100 object-fit-contain'  alt="gallery image 8" />
                                <div className='product-overlay'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Gallery
