import { Icon } from '@iconify/react'
import React, { useEffect } from 'react'
import "aos/dist/aos.css";

const ChooseUs = () => {
    return (
        <section className='section-bottom' >
            <div className='container'>
                <h1 className='title'  data-aos="fade-right"  data-aos-duration="1000">why choose us?</h1>
                <div className='row mt-2 mt-md-5'>
                    <div className='col-xl-4 col-md-6 col-12 ' data-aos="zoom-in-up"  data-aos-duration="2000">
                        <div className='choose-us-card'>
                            <div className='icon-chooseUs'>
                                <Icon icon="fa6-solid:rocket"  />
                            </div>
                            <h3>morex dependability</h3>
                            <p>
                                morex is a pioneer in the Construction Equipment manufacturing, commitment is the backbone of our company, prompt delivery of the products with assured quality & satisfaction is our edge.
                            </p>
                        </div>
                    </div>
                    <div className='col-xl-4 col-md-6 col-12 ' data-aos="zoom-in-up"  data-aos-duration="2000">
                        <div className='choose-us-card'>
                            <div className='icon-chooseUs'>
                                <Icon icon="ic:baseline-computer"  />
                            </div>
                            <h3>modern technology</h3>
                            <p>
                                For an outstanding product Quality, Error free operation & Precision Dolar uses a software driven design & product development facility to deliver the best Solution & Products.
                            </p>
                        </div>
                    </div>
                    <div className='col-xl-4 col-md-6 col-12 ' data-aos="zoom-in-up"  data-aos-duration="2000">
                        <div className='choose-us-card' >
                            <div className='icon-chooseUs'>
                                <Icon icon="ic:baseline-star"  />
                            </div>
                            <h3>quality materials</h3>
                            <p>
                                We enforce usage of highest quality raw material, accurate manufacturing process and highest Quality Controll which ensures reliable and long lasting performance.
                            </p>
                        </div>
                    </div>
                    <div className='col-xl-4 col-md-6 col-12 ' data-aos="zoom-in-up"  data-aos-duration="2000">
                        <div className='choose-us-card'>
                            <div className='icon-chooseUs'>

                                <Icon icon="pepicons-print:label"  />
                            </div>
                            <h3>affordable price</h3>
                            <p>Imagerive offers a solution and not a product from our inventory. We understand your requirement first, design and deliver the most suitable solution making sure it is affordable and meets your requirement.</p>
                        </div>
                    </div>
                    <div className='col-xl-4 col-md-6 col-12 ' data-aos="zoom-in-up"  data-aos-duration="2000">
                        <div className='choose-us-card'>
                            <div className='icon-chooseUs'>

                                <Icon icon="fluent:people-team-48-filled"  />
                            </div>
                            <h3>professional team</h3>
                            <p>
                                We have highly trained team of Engineers & Experts who make sure that the clients gets the best of products and solutions. Our team renders a seamless support for our installations across India.</p>
                        </div>
                    </div>
                    <div className='col-xl-4 col-md-6 col-12 ' data-aos="zoom-in-up"  data-aos-duration="2000">
                        <div className='choose-us-card'>
                            <div className='icon-chooseUs'>
                            <Icon icon="vaadin:user-clock" />
                            </div>
                            <h3>24 / 7 support</h3>
                            <p>
                                Un-Conditional support is offered to all our customers and our installations round the clock. We ensure that our customers never faces a break down.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ChooseUs
