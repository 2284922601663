import React from 'react'
import HeroSection from './components/heroSection'
import Service from './components/service'
import WelcomeMorex from './components/welcomeMorex'
import OurProduct from './ourProduct'
import ProductVideo from './components/productVideo'
import ProductGallery from './components/productGallery'
import ChooseUs from './components/chooseUs'

const Home = () => {
    return (
        <>
            <HeroSection />
            <WelcomeMorex />
            <OurProduct />
            <ProductVideo />
            <ProductGallery />
            <Service />
            <ChooseUs />
        </>
    )
}

export default Home
