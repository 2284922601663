import React from 'react'
import FooterLogo from '../../assets/images/logo.jpeg'
import { Icon } from '@iconify/react'

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-5 col-12">
            <div className="footer-logo">
              <img
                src={FooterLogo}
                className="object-fit-contain "
                alt="footer logo"
              />
              <div className="footer-about mt-4">
                <p>
                  Our vision is to lead the construction industry with
                  innovative machinery solutions, setting new standards of
                  excellence worldwide. We strive to revolutionize construction
                  processes, maximizing efficiency and minimizing costs. With a
                  commitment to quality and innovation, we aim to empower our
                  clients to achieve unparalleled success in their projects.
                  Join us as we pave the way for a brighter future in
                  construction. Together, let's build tomorrow's world, today.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-sm-6  mt-3 mt-xl-0 text-white">
            <h4>Quick Links</h4>
            <div className="footer-quick-link">
              <ul className="list-unstyled">
                <li>
                  <a href="/" className="text-decoration-none text-white">
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="/about-us"
                    className="text-decoration-none text-white"
                  >
                    About us
                  </a>
                </li>
                <li>
                  <a
                    href="/product"
                    className="text-decoration-none text-white"
                  >
                    products
                  </a>
                </li>
                <li>
                  <a
                    href="/gallery"
                    className="text-decoration-none text-white"
                  >
                    Gallery
                  </a>
                </li>
                <li>
                  <a
                    href="/contact-us"
                    className="text-decoration-none text-white"
                  >
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-4 col-sm-6  mt-3 mt-xl-0 mx-auto text-white">
            <h4>Newsletter</h4>
            <div className="position-relative">
              <input
                type="email"
                placeholder="Enter Email"
                className="newsletter-input"
              />
              <div className="newsletter-icon">
                <Icon icon="mdi:arrow-right-thin" className="email-news-icon" />
              </div>
            </div>
            <div className="mt-3 text-white">
              {/* <div className="col-xl-3 col-2 d-none d-xl-block"> */}
                <ul className="list-unstyled d-flex justify-content-start social-icon  mb-0">
                  <li className="cursor-pointer">
                    <Icon
                      icon="ic:outline-facebook"
                      className="social-main-icon"
                    />
                  </li>
                  <li className="cursor-pointer">
                    <Icon icon="bi:instagram" />
                  </li>
                  <li className="cursor-pointer">
                    <Icon
                      icon="material-symbols-light:mail-sharp"
                      className="social-main-icon"
                    />
                  </li>
                  <li className="cursor-pointer">
                    <Icon
                      icon="mingcute:youtube-fill"
                      className="social-main-icon"
                    />
                  </li>
                </ul>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer
