import React from 'react'

const WelcomeMorex = () => {
    return (
        <section className='section-padding welcome-main section-bottom'>
            <div className='container '>
                <div className='d-flex align-items-center flex-column col-md-10 col-12 justidy-content-center mx-auto'>
                    <h4 data-aos="fade-right"  data-aos-duration="1000">welcome to Morex</h4>
                    <p className='text-center' data-aos="fade-right"  data-aos-duration="1000">
                    At Morex, we're a respected Private Limited Company specializing in the manufacturing of various REBAR PROCESSING EQUIPMENT including BAR BENDING & BAR CUTTING MACHINES. Situated in Ahmedabad (Gujarat, India), our well-equipped infrastructure facilitates the production of high-quality products conforming to industry standards. Guided by our mentor, Mr. Vaibhav Patel, we've established a formidable presence in the national market.
                    </p>
                    <button className='btn-count read-more mt-2' data-aos="fade-up"  data-aos-duration="1000">
                        Read more
                        <span className="line-1"></span>
                        <span className="line-2"></span>
                        <span className="line-3"></span>
                        <span className="line-4"></span>
                    </button>
                </div>
            </div>
        </section>
    )
}

export default WelcomeMorex
