import React, { useEffect, useRef, useState } from 'react'
import logo from '../../assets/images/header_logo.png'
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const [small, setSmall] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", () =>
                setSmall(window.pageYOffset > 200)
            );
        }
    }, []);
    const [lastOpened, setLastOpened] = useState(null);
    const dropContainerRef = useRef(null);
    const handleDropdownToggle = (menuContent) => {
        if (lastOpened && lastOpened !== menuContent) {
            lastOpened.classList.remove("show");
        }

        menuContent.classList.toggle("show");
        setLastOpened(menuContent);
    };

    const homeScreenRedireact = ()=>{
        navigate("/");
    }
    
    return (
      <header className={`header-sticky ${small ? "scroll-header" : ""}`}>
        <div className="d-flex align-items-center justify-content-between h-100 px-sm-5 px-2">
          <div className="col-xl-3 col-md-2 col-sm-4 col-6 header-logo h-100">
            <img
              src={logo}
              className="h-100 object-fit-contain"
              alt="header logo"
              style={{ cursor: "pointer" }}
              onClick={homeScreenRedireact}
            />
          </div>
          <div className="col-xl-6 col-md-8 col-6">
            <ul className="list-unstyled d-none d-md-flex mb-0 menu-list">
              <li>
                <a href="/"> Home </a>
              </li>
              <li>
                <a href="/about-us"> About us </a>
              </li>
              <li>
                <a href="/product"> Products </a>
              </li>
              <li>
                <a href="/gallery"> Gallery </a>
              </li>
              <li>
                <a href="/contact-us"> Contact us </a>
              </li>
            </ul>

            <div className="text-end toggle-icon  d-block d-md-none">
              <Icon
                icon="ic:baseline-menu"
                onClick={() => handleDropdownToggle(dropContainerRef.current)}
              />
            </div>
            <div className="drop_container" ref={dropContainerRef}>
              <ul className="list-unstyled toggle-list-unstyled mb-0 ">
                <li className="">
                  <a className="text-black text-decoration-none" href="/">
                    {" "}
                    Home{" "}
                  </a>
                </li>
                <li className="">
                  <a
                    className="text-black text-decoration-none"
                    href="/about-us"
                  >
                    {" "}
                    About us{" "}
                  </a>
                </li>
                <li className="">
                  <a
                    className="text-black text-decoration-none"
                    href="/product"
                  >
                    {" "}
                    Products{" "}
                  </a>
                </li>
                <li className="">
                  <a
                    className="text-black text-decoration-none"
                    href="/gallery"
                  >
                    {" "}
                    Gallery{" "}
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    className="text-black text-decoration-none"
                    href="/contact-us"
                  >
                    {" "}
                    Contact us{" "}
                  </a>
                </li>
              </ul>

              <button
                className="toggle-product-button ms-sm-2"
                onClick={() => (window.location = "tel:7990377232")}
              >
                {" "}
                <Icon icon="fa-regular:comments" /> Enquire Now{" "}
              </button>
            </div>
          </div>
          <div className="d-none d-xl-block">
            <button
              className="product-button ms-sm-3"
              onClick={() => (window.location = "tel:7990377232")}
            >
              {" "}
              <Icon icon="fa-regular:comments" /> Enquire Now{" "}
            </button>
          </div>
        </div>
      </header>
    );
}

export default Header
