import React from 'react'
import product1 from '../assets/images/p1.webp'
import product2 from '../assets/images/p2.webp'

const ProductVideo = () => {
    return (
        <section className='section-padding section-bottom'>
            <div className='container'>
                <h1 className='title'  data-aos="fade-up"  data-aos-duration="1000">product videos</h1>
                <div className='row mt-5'>
                    <div className='col-md-6'  data-aos="zoom-in-right" data-aos-duration="1000">
                        <div className='video-product'>
                            <img src={product1} className='w-100 object-fit-cover' alt="product video 1" />
                        </div>
                    </div>
                    <div className='col-md-6 mt-3 mt-md-0'  data-aos="zoom-in-right"  data-aos-duration="1000">
                        <div className='video-product'>
                            <img src={product2} className='w-100 object-fit-cover' alt="product video 2"  />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProductVideo
