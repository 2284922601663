import React from 'react'
import PageHeader from './components/common/pageHeader'

const AboutUs = () => {
    return (
        <>
            <PageHeader url="About us"/>
            <section className='section-padding '>
                <div className='container'>
                    <div className='about-contain-main'>
                        <h1 data-aos="fade-right" data-aos-duration="1000">about us</h1>
                        <p data-aos="fade-right" data-aos-duration="1000">
                        Morex is a leading provider of top-notch manufacturing and distribution services for Construction Machinery & Equipment, specializing in Bar Cutting Machines, Bar Bending Machines, TMT Spiral Bending Machines, and TMT Ring Bending Machines. Renowned for our commitment to quality, reliability, and innovation, we're trusted suppliers, manufacturers, and exporters within the Construction Machinery & Equipment industry. Headquartered in Ahmedabad, Gujarat, India, we deliver excellence in every product we offer.
                        </p>
                    </div>
                    <div className='about-contain-main'>
                        <h1 data-aos="fade-right" data-aos-duration="1000">working atmosphere</h1>
                        <p data-aos="fade-right" data-aos-duration="1000">
                        Morex Engineering Solutions is an MSME-registered venture led by its Director, Mr. Vaibhav Patel. Founded in 2023, we operate from our 4000 sq ft manufacturing facility in Ahmedabad, Gujarat, India, and collaborate with experienced vendors. Our dedicated teams in Design, R&D, Quality Control, and Production departments ensure the production of hallmark products. We continuously expand our market reach by offering a wide range of Construction Machinery, simplifying construction processes while remaining cost-effective.
                        </p>
                    </div>
                    <div className='about-contain-main'>
                        <h1 data-aos="fade-right" data-aos-duration="1000">our vision</h1>
                        <p data-aos="fade-right" data-aos-duration="1000">
                        At Morex, we envision transforming the construction landscape with innovative, cost-effective machinery solutions. Our commitment to excellence drives us to redefine industry standards and enhance project efficiency worldwide.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs
