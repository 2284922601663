import React from "react";
import Header from "./header";
import Footer from "./footer";

const Layout = (props) => {

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content">
                    {props.children}
                </div>
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default Layout;