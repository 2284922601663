import React from 'react'

const PageHeader = ({ url }) => {
  return (
    <section className='element-section'>
      <div className='element-bg-overlay'>
      </div>
      <div className='element-title'>
        <h3>
          <a href='/' className='text-white text-decoration-none'>HOME </a> / {url}
        </h3>
      </div>
    </section>
  )
}

export default PageHeader
