import React from "react";
// import Slider from "react-slick";

const OurProduct = () => {
  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1
  // };

  return (
    <section className="section-padding">
      <div className="container">
        <h1 className="title" data-aos="fade-up" data-aos-duration="1000">
          our products
        </h1>
      </div>
      <div className="mt-5">
        <div className="product-slider-1">
          <div className="bg-overlay-product"></div>
          <div className="our-product-title">
            <h2 data-aos="fade-up" data-aos-duration="1000">
              {" "}
              Bar Bending Machine{" "}
            </h2>
            <p className="mt-3" data-aos="fade-up" data-aos-duration="1000">
              Planetary Gearbox Design Advance Machine & Human Safety Features
              Anti Corrosive Fully Powder Coated Body
            </p>
            <button
              className="mt-3 col-4"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Click Here
            </button>
          </div>
        </div>
        {/* <Slider {...settings}>
       
        </Slider> */}
      </div>
    </section>
  );
};

export default OurProduct;
