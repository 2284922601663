import React from 'react'
import PageHeader from './components/common/pageHeader'
import product_1 from '../src/assets/images/product_1.png'
import product_2 from '../src/assets/images/product_2.png'
import product_3 from '../src/assets/images/product_3.png'
import { Icon } from '@iconify/react'

const Product = () => {
    const downloadPdf = () => {
        // Replace 'path_to_your_pdf.pdf' with the actual path to your PDF file
        const pdfUrl = 'Morex_Broucher.pdf';
        
        // Create a new anchor element
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'Morex_Broucher.pdf'; // Name of the downloaded file
        link.target = '_blank';
    
        // Dispatch a click event to trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    return (
        <>
            <PageHeader url="Product" />
            <section className='section-padding section-bottom'>
                <div className='container'>
                    <div className='col-xl-10 mx-auto'>
                        <h1 className='page-title' data-aos="fade-up" data-aos-duration="1000">ibm42 pro</h1>
                        <div className='row mt-4 align-items-center product-contain-main '>
                            <div className='col-md-3 product-contain-img'>
                                <img src={product_1} className='object-fit-contain h-100 w-100' alt="product image" />
                            </div>
                            <div className='col-md-9 product-contain mt-2 mt-md-0'>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <button className='product-button me-sm-3' onClick={downloadPdf}> <Icon icon="material-symbols:download-sharp" className='download-icon' /> Download Brochure </button>
                            <button className='product-button ms-sm-3' onClick={() =>window.location='tel:7990377232'}> <Icon icon="fa-regular:comments" /> Enquire Now </button>
                        </div>
                        <div className='row mt-4 align-items-center product-contain-main '>
                            <div className='col-md-9 product-contain mt-2 mt-md-0'>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p>
                            </div>
                            <div className='col-md-3 product-contain-img'>
                                <img src={product_2} className='object-fit-contain h-100 w-100' alt="product image" />
                            </div>
                        </div>
                        <div className='mt-4 text-end'>
                            <button className='product-button me-sm-3' onClick={downloadPdf}> <Icon icon="material-symbols:download-sharp" className='download-icon' /> Download Brochure </button>
                            <button className='product-button ms-sm-3' onClick={() =>window.location='tel:7990377232'}> <Icon icon="fa-regular:comments" /> Enquire Now </button>
                        </div>
                        <div className='row mt-4 align-items-center product-contain-main '>
                            <div className='col-md-3 product-contain-img'>
                                <img src={product_3} className='object-fit-contain h-100 w-100' alt="product image" />
                            </div>
                            <div className='col-md-9 product-contain mt-2 mt-md-0'>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <button className='product-button me-sm-3' onClick={downloadPdf}> <Icon icon="material-symbols:download-sharp" className='download-icon' /> Download Brochure </button>
                            <button className='product-button ms-sm-3' onClick={() =>window.location='tel:7990377232'}> <Icon icon="fa-regular:comments" /> Enquire Now </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Product
